import { ECountryTrads } from "common/enums/trads/ECountryTrads";
import { ENationalityTrads } from "common/enums/trads/ENationalityTrads";
import {
	EAssociationProfesionnelleTrads,
	EAutreCgpCorporationGroupsTrads,
	EAutreCgpGroupsTrads,
	ECategorieSocialProfessionnelTrads,
	ECivilityTrads,
	ECommunityInvestmentTooltipTrads,
	ECommunityInvestmentTrads,
	EConnaissanceExperienceCorporationGroupsTrads,
	EConnaissanceExperienceGroupsTrads,
	ECorporationFormeJuridiqueTrads,
	ECorporationRegimeFiscalTrads,
	ECorporationResidenceFiscaleTrads,
	EDistributionFrequencyTrads,
	EEtatCompteTrads,
	EFinanceDurableCorporationGroupsTrads,
	EFinanceDurableGroupsTrads,
	EFinancialObjectiveTrads,
	EFormeJuridiqueTrads,
	EFundsOriginCommunityInvestmentTrads,
	EFundsOriginCorporationTrads,
	EFundsOriginTrads,
	EHabilitationTrads,
	ELegalCapacityTrads,
	ELegalStatusTrads,
	ELigneMetierTrads,
	EMaritalStatusTrads,
	EMatrimonialRegimeTrads,
	EOtherErrorsTrads,
	EPatrimoineRevenusCorporationGroupsTrads,
	EPatrimoineRevenusGroupsTrads,
	EPaymentMethodTrads,
	EProductCategoriesTrads,
	EProductDocumentTypeTrads,
	EProductFileTypeTrads,
	EQualiteRepresentantTrads,
	ESubCategorisationMifCorporationTrads,
	ESubChargeAnnuelleFoyerFiscalTrads,
	ESubCombienTempsRealisezInvestissementsCorporationTrads,
	ESubCombienTempsRealisezInvestissementsTrads,
	ESubCommentConnuConsultimCorporationTrads,
	ESubCommentConnuConsultimTrads,
	ESubConnaissancesMarcheFinancierProduitsEpargneCorporationTrads,
	ESubConnaissancesMarcheFinancierProduitsEpargneTooltipTrads,
	ESubConnaissancesMarcheFinancierProduitsEpargneTrads,
	ESubConnaissancesMarcheImmobilierCorporationTrads,
	ESubConnaissancesMarcheImmobilierTooltipTrads,
	ESubConnaissancesMarcheImmobilierTrads,
	ESubDejaSouscritCombienCorporationTrads,
	ESubDejaSouscritPartCorporationTrads,
	ESubDejaSouscritQuandCorporationTrads,
	ESubDejaSouscritScpiPartPiervalCorporationTrads,
	ESubDejaSouscritScpiPartPiervalTrads,
	ESubDejaSouscritTypeCorporationTrads,
	ESubFilesTypesTrads,
	ESubFiscaliteApplicableScpiCorporationTrads,
	ESubFiscaliteApplicableScpiTrads,
	ESubFrequenceTransactionCorporationTrads,
	ESubHorizonInvestissementCorporationTrads,
	ESubHorizonInvestissementTrads,
	ESubInvestissementPatrimoineGlobalTrads,
	ESubModeEntreeRelationCorporationTrads,
	ESubModeEntreeRelationTrads,
	ESubModeGestionUtiliseCorporationTrads,
	ESubModeGestionUtiliseTrads,
	ESubMoyenneTransactionCorporationTrads,
	ESubNbOperationsDerniersMoisTrads,
	ESubNbOperationsSansConseillerCorporationTrads,
	ESubOriginePatrimoineCorporationTrads,
	ESubPartInvestissementPatrimoineGlobalCorporationTrads,
	ESubPartInvestissementsDurablesCorporationTrads,
	ESubPartInvestissementsDurablesTrads,
	ESubPerformanceScpiFonctionCorporationTrads,
	ESubPerformanceScpiFonctionTrads,
	ESubPeriodicitePiervalCorporationTrads,
	ESubPlacementPeuLiquiditeCorporationTrads,
	ESubPreferenceDurabiliteCorporationTooltipTrads,
	ESubPreferenceDurabiliteCorporationTrads,
	ESubPreferenceDurabiliteTooltipTrads,
	ESubPreferenceDurabiliteTrads,
	ESubReactionChuteBrusqueValeurInvestissementCorporationTrads,
	ESubReactionChuteBrusqueValeurInvestissementTrads,
	ESubRisqueRendementAccepterTrads,
	ESubRisqueRendementCorporationTrads,
	ESubScpiImpacteesParCorporationTrads,
	ESubScpiImpacteesParTrads,
	ESubScpiOpciFrequenceTransactionTrads,
	ESubScpiOpciMoyenneTransactionCoutTrads,
	ESubScpiOpciNombreSouscriptionsTrads,
	ESubScpiRecourtCreditCorporationTrads,
	ESubScpiRecourtCreditTrads,
	ESubscriptionTypeTrads,
	ESubSituationPatrimonialeTrads,
	ESubStatutFactaCrsCorporationTrads,
	ESubStepCGPTrads,
	ESubStepMOTrads,
	ESubSupportPlacementCorporationTrads,
	ESubSupportPlacementTrads,
	ESubTrancheMarginaleImpositionTrads,
	ETestAdequationCorporationGroupsTrads,
	ETestAdequationGroupsTrads,
	ETypeConseillerTrads,
	EValidationErrorTrads,
} from "common/enums/trads/index";
import { EProductInformation } from "components/pages/Products/Product/elements/ProductInformation";
import { ESteps } from "components/pages/Subscriptions/corporation/CreateCorporation";

const ECorporationStepsTrads: Record<ESteps, string> = {
	CORPORATION_INFO: "Informations société",
	LEGAL_REPRESENTATIVE: "Représentant légal",
	BENEFICIARIES: "Bénéficiaires effectifs",
};

const EProductInformationTrads: Record<EProductInformation, string> = {
	price: "Prix de part",
	category: "Catégorie",
	minNumberOfShares: "Minimum de parts (à la première souscription)",
	subFees: "Frais de souscription",
	dueDate: "Délai de jouissance",
	withdrawalPrice: "Prix de retrait",
	distributionRate: "Taux de distribution N-1",
	distributionRateN2: "Taux de distribution N-2",
	distributionFrequency: "Fréquence de distribution",
	capitalization: "Capitalisation",
	financialOccupancyRate: "Taux d'occupation financier",
	retainedEarnings: "Report à nouveau",
};

const trads = {
	common: {
		continue: "Continuer",
		cancel: "Annuler",
		logout: "Déconnexion",
		consults: "Consulter",
		search: "Rechercher",
		yes: "Oui",
		no: "Non",
		save: "Enregistrer",
		back: "Retour",
		delete: "Supprimer",
		add: "Ajouter",
		next: "Suivant",
		next_page: "Passer à l'étape suivante",
		back_to: "Reprendre",
		close: "Fermer",
		other: "Autre",
		souscripteur1: "Souscripteur 1",
		souscripteur2: "Souscripteur 2",
		civility_cosubscription: "Conjoints",
		results: "Résultats",
		warning: "Attention",
		see_detail: "Voir le détail",
		no_content: "Pas de contenu pour le moment",
		loading: "Chargement...",
		find_your_address: "Trouvez votre adresse",
		back_to_subscriptions: "Retour aux souscriptions",
		back_to_clients: "Retour aux clients",
		end: "Fin",
		step: "Étape",
		modify: "Modifier",
		not_valid_client: "Ce client contient des informations invalides ou incomplètes pour entamer une souscription. \n\n Sélectionnez-le pour le compléter",
		modify_informations: "Modifier les informations",
		save_informations: "Enregistrer les informations",
		actif: "Actif",
		inactif: "Inactif",
		invite: "Inviter",
		invited: "Invité",
		send: "Envoyer",
		resend_in_5_minutes: "Le mail d'invitation a été envoyé.\n Vous pourrez renvoyer un mail dans 5 minutes",
	},
	enums: {
		EPaymentMethod: EPaymentMethodTrads,
		ESubSituationPatrimoniale: ESubSituationPatrimonialeTrads,
		ESubTrancheMarginaleImposition: ESubTrancheMarginaleImpositionTrads,
		ESubInvestissementPatrimoineGlobal: ESubInvestissementPatrimoineGlobalTrads,
		ESubPartInvestissementPatrimoineGlobalCorporation: ESubPartInvestissementPatrimoineGlobalCorporationTrads,
		ESubChargeAnnuelleFoyerFiscal: ESubChargeAnnuelleFoyerFiscalTrads,
		EFinancialObjective: EFinancialObjectiveTrads,
		EFundsOrigin: EFundsOriginTrads,
		ESubModeGestionUtilise: ESubModeGestionUtiliseTrads,
		ESubRisqueRendementAccepter: ESubRisqueRendementAccepterTrads,
		ESubScpiOpciNombreSouscriptions: ESubScpiOpciNombreSouscriptionsTrads,
		ESubScpiOpciFrequenceTransaction: ESubScpiOpciFrequenceTransactionTrads,
		ESubScpiOpciMoyenneTransactionCout: ESubScpiOpciMoyenneTransactionCoutTrads,
		ESubConnaissancesMarcheFinancierProduitsEpargne: ESubConnaissancesMarcheFinancierProduitsEpargneTrads,
		ESubConnaissancesMarcheFinancierProduitsEpargneTooltip: ESubConnaissancesMarcheFinancierProduitsEpargneTooltipTrads,
		ESubCombienTempsRealisezInvestissements: ESubCombienTempsRealisezInvestissementsTrads,
		ESubConnaissancesMarcheImmobilier: ESubConnaissancesMarcheImmobilierTrads,
		ESubConnaissancesMarcheImmobilierTooltip: ESubConnaissancesMarcheImmobilierTooltipTrads,
		ESubReactionChuteBrusqueValeurInvestissement: ESubReactionChuteBrusqueValeurInvestissementTrads,
		ECivility: ECivilityTrads,
		EMaritalStatus: EMaritalStatusTrads,
		ELegalCapacity: ELegalCapacityTrads,
		EMatrimonialRegime: EMatrimonialRegimeTrads,
		ESubHorizonInvestissement: ESubHorizonInvestissementTrads,
		ESubHorizonInvestissementCorporation: ESubHorizonInvestissementCorporationTrads,
		ESubPartInvestissementsDurables: ESubPartInvestissementsDurablesTrads,
		ESubPartInvestissementsDurablesCorporation: ESubPartInvestissementsDurablesCorporationTrads,
		ESubPreferenceDurabilite: ESubPreferenceDurabiliteTrads,
		ESubPreferenceDurabiliteCorporation: ESubPreferenceDurabiliteCorporationTrads,
		ESubPreferenceDurabiliteTooltip: ESubPreferenceDurabiliteTooltipTrads,
		ESubPreferenceDurabiliteCorporationTooltip: ESubPreferenceDurabiliteCorporationTooltipTrads,
		ESubModeEntreeRelation: ESubModeEntreeRelationTrads,
		ESubModeEntreeRelationCorporation: ESubModeEntreeRelationCorporationTrads,
		ESubStepMO: ESubStepMOTrads,
		ESubStepCGP: ESubStepCGPTrads,
		ECountry: ECountryTrads,
		ENationality: ENationalityTrads,
		EValidationErrors: EValidationErrorTrads,
		ETypeConseiller: ETypeConseillerTrads,
		EHabilitation: EHabilitationTrads,
		ELigneMetier: ELigneMetierTrads,
		EEtatCompte: EEtatCompteTrads,
		EFormeJuridique: EFormeJuridiqueTrads,
		EAssociationProfesionnelle: EAssociationProfesionnelleTrads,
		ESubCommentConnuConsultim: ESubCommentConnuConsultimTrads,
		ESubCommentConnuConsultimCorporation: ESubCommentConnuConsultimCorporationTrads,
		ESubNbOperationsDerniersMois: ESubNbOperationsDerniersMoisTrads,
		ESubPerformanceScpiFonction: ESubPerformanceScpiFonctionTrads,
		ESubPerformanceScpiFonctionCorporation: ESubPerformanceScpiFonctionCorporationTrads,
		ESubScpiRecourtCredit: ESubScpiRecourtCreditTrads,
		ESubScpiRecourtCreditCorporation: ESubScpiRecourtCreditCorporationTrads,
		ESubScpiImpacteesPar: ESubScpiImpacteesParTrads,
		ESubScpiImpacteesParCorporation: ESubScpiImpacteesParCorporationTrads,
		ESubFiscaliteApplicableScpi: ESubFiscaliteApplicableScpiTrads,
		ESubFiscaliteApplicableScpiCorporation: ESubFiscaliteApplicableScpiCorporationTrads,
		EProductCategories: EProductCategoriesTrads,
		ETestAdequationGroups: ETestAdequationGroupsTrads,
		ETestAdequationCorporationGroups: ETestAdequationCorporationGroupsTrads,
		EPatrimoineRevenusGroups: EPatrimoineRevenusGroupsTrads,
		EPatrimoineRevenusCorporationGroups: EPatrimoineRevenusCorporationGroupsTrads,
		EFinanceDurableGroups: EFinanceDurableGroupsTrads,
		EFinanceDurableCorporationGroups: EFinanceDurableCorporationGroupsTrads,
		EConnaissanceExperienceGroups: EConnaissanceExperienceGroupsTrads,
		EConnaissanceExperienceCorporationGroups: EConnaissanceExperienceCorporationGroupsTrads,
		EAutreCgpGroups: EAutreCgpGroupsTrads,
		EAutreCgpCorporationGroups: EAutreCgpCorporationGroupsTrads,
		ESubFilesTypes: ESubFilesTypesTrads,
		EOtherErrors: EOtherErrorsTrads,
		EDistributionFrequency: EDistributionFrequencyTrads,
		ECommunityInvestment: ECommunityInvestmentTrads,
		ECommunityInvestmentTooltip: ECommunityInvestmentTooltipTrads,
		ELegalStatus: ELegalStatusTrads,
		ECorporationSteps: ECorporationStepsTrads,
		ECorporationRegimeFiscal: ECorporationRegimeFiscalTrads,
		ECorporationResidenceFiscale: ECorporationResidenceFiscaleTrads,
		ECorporationFormeJuridique: ECorporationFormeJuridiqueTrads,
		EQualiteRepresentant: EQualiteRepresentantTrads,
		ECategorieSocialProfessionnel: ECategorieSocialProfessionnelTrads,
		ESubCategorisationMifCorporation: ESubCategorisationMifCorporationTrads,
		ESubStatutFactaCrsCorporation: ESubStatutFactaCrsCorporationTrads,
		ESubPeriodicitePiervalCorporation: ESubPeriodicitePiervalCorporationTrads,
		ESubPlacementPeuLiquiditeCorporation: ESubPlacementPeuLiquiditeCorporationTrads,
		ESubscriptionType: ESubscriptionTypeTrads,
		ESubDejaSouscritQuandCorporation: ESubDejaSouscritQuandCorporationTrads,
		ESubDejaSouscritCombienCorporation: ESubDejaSouscritCombienCorporationTrads,
		ESubDejaSouscritTypeCorporation: ESubDejaSouscritTypeCorporationTrads,
		ESubDejaSouscritPartCorporation: ESubDejaSouscritPartCorporationTrads,
		ESubFrequenceTransactionCorporation: ESubFrequenceTransactionCorporationTrads,
		ESubMoyenneTransactionCorporation: ESubMoyenneTransactionCorporationTrads,
		ESubModeGestionUtiliseCorporation: ESubModeGestionUtiliseCorporationTrads,
		ESubNbOperationsSansConseillerCorporation: ESubNbOperationsSansConseillerCorporationTrads,
		ESubRisqueRendementCorporation: ESubRisqueRendementCorporationTrads,
		ESubConnaissancesMarcheFinancierProduitsEpargneCorporation: ESubConnaissancesMarcheFinancierProduitsEpargneCorporationTrads,
		ESubCombienTempsRealisezInvestissementsCorporation: ESubCombienTempsRealisezInvestissementsCorporationTrads,
		ESubReactionChuteBrusqueValeurInvestissementCorporation: ESubReactionChuteBrusqueValeurInvestissementCorporationTrads,
		ESubConnaissancesMarcheImmobilierCorporation: ESubConnaissancesMarcheImmobilierCorporationTrads,
		EFundsOriginCorporation: EFundsOriginCorporationTrads,
		ESubSupportPlacementCorporation: ESubSupportPlacementCorporationTrads,
		ESubSupportPlacement: ESubSupportPlacementTrads,
		ESubOriginePatrimoineCorporation: ESubOriginePatrimoineCorporationTrads,
		EProductInformation: EProductInformationTrads,
		EProductFileType: EProductFileTypeTrads,
		EProductDocumentType: EProductDocumentTypeTrads,
		ESubDejaSouscritScpiPartPierval: ESubDejaSouscritScpiPartPiervalTrads,
		ESubDejaSouscritScpiPartPiervalCorporation: ESubDejaSouscritScpiPartPiervalCorporationTrads,
		EFundsOriginCommunityInvestment: EFundsOriginCommunityInvestmentTrads,
	},
	pages: {
		home: {
			page_title: "Accueil",
		},
		not_found: {
			subtitle: "Oups ! La page que vous cherchez semble introuvable.",
			button: "Retour à l'accueil",
		},
		client: {
			page_title: "Client",
			sub_pages: {
				legal_person_clients: {
					page_title: "Clients personnes morales",
				},
				physical_person_clients: {
					page_title: "Clients personnes physiques",
				},
				personal_information: {
					page_title: "Informations personnelles",
					modify_information: "Modifier les informations",
					see_spouse: "Voir la fiche du conjoint",
				},
				validated_subscriptions: {
					page_title: "Souscriptions validées",
					table: {
						columns: {
							name: "Nom du produit",
							validation_date: "Date de souscription",
							due_date: "Date de jouissance",
							payout_date: "Date de versement des premiers loyers",
							share_number: "Nombre de parts",
							amount_subscribed: "Montant souscrit (€)",
							estimation_amount: "Valorisation estimée*",
						},
					},
				},
				customer_insight: {
					page_title: "Connaissance client",
					no_data:
						"Il semble qu'aucune question de cette catégorie n'ait été complétée pour ce client.\n\nLes réponses que vous fournirez lors des souscriptions seront enregistrées ici. Elles permettront de vous faire gagner du temps en pré-remplissant les futures souscriptions de ce client.",
					sub_pages: {
						payment_method: {
							page_title: "Règlement",
						},
						patrimoine_revenus: {
							page_title: "Patrimoine et revenus",
						},
						finance_durable: {
							page_title: "Finance durable",
						},
						connaissance_experience: {
							page_title: "Connaissance et expérience",
						},
						test_adequation: {
							page_title: "Test d'adéquation",
						},
					},
				},
			},
		},
		corporations: {
			table: {
				columns: {
					raisonSociale: "Raison sociale",
					formeJuridique: "Forme juridique",
					montantTotalSouscriptions: "Montant total de souscriptions (€)",
					dateLastSubscription: "Date de la dernière souscription",
					nbProduitsSouscrits: "Nombre de produits souscrits",
				},
			},
		},
		clients: {
			page_title: "Vos clients",
			table: {
				columns: {
					civility: "Civilité",
					last_name: "Nom",
					first_name: "Prénom",
					subscription_sum: "Montant total des souscriptions",
					last_subscription_date: "Date de la dernière souscription",
					number_of_subscriptions: "Nombre de produits souscrits",
				},
			},
		},
		mo_upload_documents: {
			page_title: "Dépôt des documents",
			input_name_label: "Nom du document",
			cta: "Ajouter le document",
			modal: {
				title: "Êtes-vous sûr ?",
				text: "Cette action est irréversible.",
				cta: "Supprimer",
			},
		},
		subscription_information: {
			page_title: "Souscription {{productName}} de {{civility}} {{subscriberName}}",
			page_title_corporation: "Souscription {{productName}} de {{corporationName}}",
			title: "Informations de la souscription",
			validation_date: "Date de validation",
			due_date: "Date de jouissance",
			payout_date: "Date de versement des premiers loyers",
			share_number: "Nombre de parts",
			amount_subscribed: "Montant souscrit",
			estimation_amount: "Valorisation estimée",
			attestation: "Attestation de part",
			signed: "Souscription signée par le client",
			estimation_amount_info:
				"Valorisation estimée (valeur de retrait) en fonction du nombre de parts possédé par le client à ce jour à notre connaissance, sous réserve de vérification auprès de la société de gestion",
		},
		subscription_summary: {
			page_title: "Souscription {{productName}} de {{civility}} {{subscriberName}}",
			sub_pages: {
				subscribers: {
					page_title: "Souscripteur(s)",
					corporationInfo: "Informations société",
					legal_representative: "Représentant légal",
					beneficiary: "Bénéficiaire effectif",
				},
				payment: {
					page_title: "Mode de règlement",
				},
				bulletin: {
					page_title: "Bulletin de souscription",
				},
				files: {
					page_title: "Pièces justificatives",
				},
			},
		},
		subscriptions: {
			page_title: "Souscriptions",
			dropdown_button_text: "Lancer une nouvelle souscription",
			back_to_subscriptions: "Retour aux souscriptions",
			table: {
				find_subscrition: "Chercher une souscription",
				civility: "Civilité",
				lastname: "Nom",
				name: "Prénom",
				product_name: "Nom du produit",
				amount_subscribed: "Montant souscrit",
				payment_method: "Mode de réglement",
			},
			unsaved_modifications: "Les modifications apportées n'ont pas été enregistrées.",
			continue_unsave: "Continuer sans enregistrer",
			analyse_processing: "Analyse en cours",
			sub_pages: {
				in_progress: {
					page_title: "En cours (non envoyé à wenimmo)",
					civility: "Civilité",
					lastname: "Nom",
					name: "Prénom",
					subscriber: "Souscripteur",
					number_of_products: "Nombre de produits",
					amount_subscribed: "Montant souscrit",
					type: "Type",
					modal: {
						title: "Êtes-vous sûr ?",
						irreversible: "Cette action est irréversible.",
					},
				},
				waiting_signature: {
					page_title: "En attente de signature",
					date: "Date d'envoi pour signature au client",
				},
				waiting_transfer_sg: {
					page_title: "À transmettre SDG",
					date: "Date d'envoi à la société de gestion",
					button: "Transmettre",
				},
				waiting_validation: {
					page_title: "En attente de validation société de gestion",
					date: "Date d'envoi à la société de gestion",
				},
				validated: {
					page_title: "Validées",
					date: "Date de validation",
				},
			},
			existing_clients: {
				page_title: "Clients existants",
				title: "Sélectionner le client",
				launch_co_subscription: "Lancer une co-souscription",
				launch_subscription: "Lancer une souscription seul",
				modal_no_co_suscriber: {
					title: "Conjoint introuvable",
					text: "Le client sélectionné n'a pas encore de conjoint",
					cta: "Créer la fiche du conjoint",
				},
				modal_not_valid_client: {
					title: "Attention",
					spouse_title: "Attention, conjoint non valide",
					text: "Veuillez compléter l'ensemble des informations afin de pouvoir souscrire pour ce client.",
				},
			},
			existing_corporation: {
				page_title: "Selection d'une personne morale",
				title: "Sélectionner le client",
				subtitle: "Sélectionnez dans la liste ci-dessous la société concernée par cette souscription.",
				launch_co_subscription: "Lancer une co-souscription",
				launch_subscription: "Lancer une souscription",
				modal_no_co_suscriber: {
					title: "Conjoint introuvable",
					text: "Le client sélectionné n'a pas encore de conjoint",
					cta: "Créer la fiche du conjoint",
				},
			},
			upload_documents: {
				page_title: "Dépôt des documents",
				finalize: "Finaliser la souscription",
				description:
					"Pour terminer votre parcours, merci de bien vouloir télécharger l'ensemble des justificatifs nécessaires à la validation de votre dossier auprès des sociétés de gestion.",
				checkbox_text:
					"Dans le cadre du document d'entrée en relation signé par mon client, celui-ci accepte de transmettre ses données personnelles pour le traitement de son dossier.",
				documents_needed: "Faites attention a bien fournir tous les documents demandés.",
				modal: {
					title: "Souscription finalisée",
					description:
						"Le dossier a été transmis au back office de Wenimmo.\n\nAprès étude du dossier par nos services, vous serez notifié de l'envoi du dossier en signature électronique à votre client.\n\nVous avez également la possibilité de nous contacter :\n",
					address: "backoffice@wenimmo.com",
				},
				uploaders: {
					cartId: "Pièce d'identité (en cours de validité)*",
					rib: "RIB de perception des dividendes*",
					rib_prelevement: "RIB de prélèvement (si différent du RIB dividendes)",
					domicileProof: "Justificatif de Domicile (moins de 3 mois)*",
					fundsOrigin: "Justificatif(s) d'Origine des Fonds*",
					others: "Autres documents (traçabilité des fonds, attestation, ...)",
					spouseCartId: "Pièce d'identité du conjoint*",
					rib_corporation: "RIB de perception des dividendes*",
					rib_prelevement_corporation: "RIB de prélèvement (si différent du RIB dividendes)",
					kbis: "Kbis infogreffe (de moins de 3 mois) *",
					certified_status: "Statuts certifiés conformes (de moins de 3 mois) * ",
					card_id_legal_representative: "Carte d'identité du représentant légal*",
					justificatif_domicile_legal_representative: "Justificatif de domicile du représentant légal*",
					beneficiaries_list: "Liste des bénéficiaires effectifs*",
					justificatif_origine_fonds_corporation: "Justificatif de l'origine des fonds*",
					beneficiaries_id: "Pièce d'identité des bénéficiaires effectifs*",
					beneficiaries_proof_address: "Justificatif de domicile des bénéficiaires effectifs*",
					liasse_fiscale: "Liasse fiscale ou formulaire 2072 (le cas échéant)",
				},
			},
			create_client: {
				page_title: "Création nouveau client",
				title: "Création nouveau client",
				back: "Retour aux souscriptions",
			},
			create_corporation: {
				page_title: "Personne morale",
				title: "Création personne morale",
				back: "Retour aux souscriptions",
				beneficiaries: {
					is_legal_representative_beneficiary: "Le représentant légal est-il un bénéficiaire effectif ?",
					beneficiary_is: "Le bénéficiaire effectif est un",
					add_beneficiary: "Ajouter un bénéficiaire",
					effectiv_beneficiary: "Bénéficiaire effectif",
					beneficiary: "Bénéficiaire",
					new_client: "Nouveau client",
					existing_client: "Client existant",
					edit_beneficiaries: "Edition du bénéficiaire",
				},
				legal_representative: {
					legal_representative_is: "Le représentant légal est un",
					message_signataire: "Fournir le pouvoir du signataire le cas échéant",
					legal_representative: "Représentant légal",
					edit_legal_representative: "Edition du représentant légal",
				},
			},
			summary: {
				page_title: "Récapitulatif",
				page_title_corporation: "Récapitulatif",
				title: "Récapitulatif",
			},
			select_products: {
				page_title: "Sélection des produits",
				title: "Sélection des produits",
				subtitle: "Sélectionnez les produits auxquels votre client souhaite souscrire et ajoutez-les au panier.",
				next_page: "Passer à l'étape suivante",
				total_amount: "Montant total de la souscription",
				table: {
					columns: {
						scpi: "SCPI disponibles",
						paiement_method: "Mode de réglement",
						quantity: "Nombre de parts",
						amount: "Nombre de parts",
						calculated_price: "Montant de la souscription",
						start_price: "Prix de part",
					},
					placeholders: {
						select_scpi: "Sélectionner une SCPI",
						select_paiement_method: "Sélectionner un mode de réglement",
					},
				},
				modal: {
					text: `Il est nécessaire d'acquérir un minimum de {{minimumShare}} parts lors de la première souscription à ce produit.Avant de procéder à la souscription, veuillez vérifier que votre client détient déjà des parts de cette SCPI.`,
					button: "J'ai compris",
				},
			},
			payment_methods: {
				page_title_corporation: "Règlement",
				page_title: "Réglement",
				title: "Règlement",
				description: "Spécifiez les modalités de règlement des souscriptions de votre client.",
				prelevement_info:
					"Si co-souscription : Règlement d'un compte joint recommandé. Si souscription seule : Règlement d'un compte au nom seul du souscripteur recommandé.",
			},
			patrimoine_revenus: {
				page_title: "Patrimoine et revenus",
				page_title_corporation: "Patrimoine et revenus",
				title: "Patrimoine et revenus",
				questions: {
					dontEnfantsACharge: "Dont enfants à charge",
					situationFinanciereCorporation: {
						capitauxPropre: "Capitaux propres",
						totalBilan: "Total du bilan",
						resultatNet: "Résultat net",
						capitalSocial: "Capital social",
						chiffreDAffaire: "Chiffre d'affaire",
					},
					compositionPatrimoine: {
						immobilierUsage: "Immobilier d'usage",
						immobilierLocatif: "Immobilier locatif",
						actifsFinanciers: "Autres actifs financiers",
						assuranceVie: "Assurance vie",
						liquidites: "Liquidités",
						actifsProfessionnels: "Actifs professionnels",
						epargneRetraite: "Epargne retraite",
						scpi: "SCPI",
						actions: "Actions",
						obligations: "Obligations",
						autre: "Autre",
					},
					compositionPatrimoineCorporation: {
						patrimoineImmobilier: "Patrimoine Immobilier",
						patrimoineFinancier: "Patrimoine financier",
						titresScpiOpci: "dont titres SCPI/OPCI",
						liquiditesTresorerie: "Liquidités/Trésorerie",
					},
					originePatrimoine: {
						salaire: "Salaire",
						valorisation: "Création/valorisation d'une entreprise ou d'un fonds de commerce",
						heritage: "Héritage",
					},
					originePatrimoineCorporation: {
						salaire: "Salaire",
						creation_valorisation_entreprise: "Création/valorisation d'une entreprise ou d'un fonds de commerce",
						heritage: "Héritage",
					},
					compositionRevenus: {
						immobiliers: "Revenus immobiliers",
						salaires: "Salaires",
						retraites: "Retraites",
						financiers: "Revenus financiers",
						mobiliers: "Revenus mobiliers",
						autres: "Autre",
					},
					compositionRevenusCorporation: {
						revenusImmobiliers: "Revenus immobiliers",
						activite: "Activité",
						retraites: "Retraites",
						revenusFinanciers: "Revenus financiers",
						autre: "Autre",
					},
					revenuCharge: {
						nature: "Nature",
						montant: "Montant",
					},
				},
			},
			files: {
				page_title: "Documents",
				page_title_corporation: "Documents",
				title: "Documents",
			},
			connaissance_experience: {
				page_title: "Connaissance & expérience",
				page_title_corporation: "Connaissance & expérience représentant légal",
				title: "Connaissance & expérience",
				supportPlacement: {
					queVousConnaissez: "Que vous connaissez",
					dontVousDisposez: "Dont vous disposez",
					nombreSouscriptions: "Nombre de souscriptions au cours des 12 derniers mois",
					frequenceTransaction: "Fréquence moyenne des transactions",
					moyenneTransaction: "Moyenne de transaction en €",
					scpiOpciNombreSouscriptions: "Nombre de souscriptions de parts de SCPI",
				},
			},
			autre_cgp: {
				page_title: "Appréciation sur l'opération",
				page_title_corporation: "Appréciation sur l'opération",
				title: "Appréciation sur l'opération",
			},
			test_adequation: {
				page_title: "Test d'adéquation",
				page_title_corporation: "Test d'adéquation",
				title: "Test d'adéquation",
			},
			finance_durable: {
				page_title: "Finance durable",
				page_title_corporation: "Finance durable",
				title: "Finance durable",
			},
			new_subscription_modal: {
				title: "Lancer une souscription",
				new_client: "Nouveau client",
				existing_client: "Client existant",
			},
		},
		products: {
			page_title: "Produits",
			completed: {
				label: "Complet",
			},
			not_available: {
				label: "Pas de mapping",
			},
			incompleted: {
				label: "Incomplet",
				tooltip: "Produit non digitalisable : Uploader les documents de souscription pour ce produit.",
			},
			table: {
				columns: {
					status: "Statut",
					product: "Produit",
					distribution: "Taux de distribution N-1",
					distribution_n2: "Taux de distribution N-2",
					distribution_frequency: "Fréquence de distribution",
					capitalization: "Capitalisation",
					occupation: "Taux d'occupation financier",
					report: "Report à nouveau (jours)",
					category: "Catégorie",
				},
			},
			history_table: {
				columns: {
					data: "Donnée",
					modificationDate: "Date de modification",
					creator: "Responsable",
				},
			},
		},
		product: {
			page_title: "Produit",
			own_by: "Société de gestion",
			new_subscription: "Lancer une nouvelle souscription",
			subscription_documents: "Documents de souscription",
			signed_documents: "Documents signés",
			back_to_products: "Retour aux produits",
			modify_data: "Modifier les données",
			modify_name: "Modifier le nom",
			add_document: "Ajouter un document",
			add_the_document: "Ajouter le document",
			deposit_document: "Déposer un document",
			no_document: "Aucun document",
			download_all_documents: "Télécharger tous les documents",
			trimestrial_report_history: "Historique des bulletins trimestriels",
			rename: "Renommer",
			delete_document: "Supprimer le document",
			irreverible_action: "Cette action est irréversible.",
			type: "Type",
			document_name: "Nom du document",
			data_history: "Historique des données",
			download: "Télécharger",
			documents_table: {
				name: "Nom",
				updated_at: "Date de mise à jour",
			},
			informations: {
				title: "Information de souscription",
				price: "Prix de part",
				min_number_of_shares: "Minimum de parts (à la première souscription)",
				share: "part",
				shares: "parts",
				due_date: "Délai de jouissance",
				due_date_message: "{{dueDayVar}} jour du {{dueMonthVar}} mois suivant la date de souscription",
				day: "jour",
				days: "jours",
				months: "mois",
				withdrawal_price: "Prix de retrait",
				distribution_rate: "Taux de distribution N-1",
				distribution_rate_n2: "Taux de distribution N-2",
				distribution_frequency: "Fréquence de distribution",
				financial_occupancy_rate: "Taux d'occupation financier",
				capitalization: "Capitalisation",
				retained_earnings: "Report à nouveau (jours)",
				sub_fees: "Frais de souscription",
				category: "Catégorie",
			},
			signable_documents: {
				missing_template: {
					label: "Document manquant",
					tooltip: "Produit non digitalisé : il n'est pas encore possible de générer les documents de souscription pour ce produit.",
				},
				rto_document: "Document RTO",
			},
		},
		document_template_subscription: {
			page_title: "{{productName}} - Documents de souscription",
			title: "{{productName}} - Documents de souscription",
			sub_pages: {
				corporation: {
					label: "Documents personne morale",
				},
				physical_person: {
					label: "Documents personne physique",
				},
			},
		},
		account: {
			page_title: "Mon compte",
			my_informations: "Mes informations",
			password: "Mot de passe",
			my_signature: "Ma signature manuscrite",
			create_signature: "Créer une signature",
			modify_signature: "Modifier la signature",
			modify_password: "Modifier le mot de passe",
			change_password: "Changer de mot de passe",
			signature_info:
				"Votre signature manuscrite est requise pour réaliser des souscriptions. Elle sera utilisée sur les documents envoyés aux sociétés de gestion, sans que vous ayez à signer électroniquement.",
			password_requirements: {
				title: "Le mot de passe doit au moins contenir:",
				min_length: "12 caractères",
				lowercase: "Une minuscule",
				uppercase: "Une majuscule",
				number: "Un chiffre",
				special_character: "Un caractère spécial",
			},
			current_password: {
				label: "Mot de passe actuel",
				placeholder: "Entrer le mot de passe",
			},
			new_password: {
				label: "Nouveau mot de passe",
				placeholder: "Entrer le nouveau mot de passe",
			},
			confirm_new_password: {
				label: "Confirmer le mot de passe",
				placeholder: "Confirmer le mot de passe",
			},
		},
		login: {
			welcome: {
				page_title: "Bienvenue",
				title: "Bienvenue sur Wenimmo",
				description: "Configurez votre mot de passe afin d'acceder a la plateforme",
				placeholder: "Entrer l'e-mail",
				button: "Se connecter",
			},
			connect: {
				page_title: "Connection",
				title: "Bienvenue sur Wenimmo",
				description: "Configurez votre mot de passe afin d'acceder a la plateforme",
				label: "Mot de passe",
				placeholder: "Entrer le mot de passe",
				forgot_password: "Mot de passe oublié ?",
				button: "Se connecter",
			},
			request_pin_code: {
				label: "Code pin à 6 chiffres",
				send_pin_code: "Envoyer le code par mail",
				resend_pin_code_in: "Renvoyer un mail dans: ",
				initialize_password: {
					page_title: "Creation de compte",
					title: "Créer votre mot de passe",
					description:
						"Un code de sécurité à 6 chiffres a été envoyé sur votre boite mail afin de vérifier que vous en êtes bien le propriétaire.\n\nVeuillez renseigner ce code ci-dessous (vérifiez vos spams).",
				},
				forgotten_password: {
					page_title: "Mot de passe oublié",
					title: "Mot de passe oublié ?",
					description: "Afin de réinitialiser votre mot de passe, vous devez entrer un code pin à 6 chiffres envoyé dans votre boite mail.",
				},
			},
			reset_password: {
				create_password: "Créer un mot de passe",
				confirm_password: "Confirmer le mot de passe",
				enter_password: "Entrer votre mot de passe",
				requirements: "Au moins 12 caractères, une minuscule, une majuscule, un chiffre, un caractère spécial",
				initialize_password: {
					page_title: "Creation de compte",
					title: "Initialiser votre mot de passe",
					description: "Créer votre mot de passe",
					success_button: "S'enregister",
				},
				forgotten_password: {
					page_title: "Mot de passe oublié",
					title: "Réinitialiser le mot de passe",
					description: "Créer votre nouveau mot de passe",
					success_button: "Réinitialiser le mot de passe",
				},
			},
		},
		mo_subscriptions: {
			page_title: "Souscriptions",
			title: "Espace souscription",
			sub_pages: {
				draft: {
					page_title: "Brouillons",
					date: "Date de création",
				},
				table: {
					find_subscription: "Chercher une souscription",
					full_name: "Souscripteur",
					cabinet_name: "Nom du cabinet (CGP)",
					cgp_email: "Email du CGP",
					cgp_phone: "Téléphone du CGP",
					payment_method: "Méthode de réglement",
					scpi_name: "Nom de la SCPI",
					amount: "Montant",
					number_of_shares: "Nombre de parts",
					status: "Statut",
					type: "Type",
				},
				to_process: {
					page_title: "À traiter",
					date: "Date de réception",
				},
				waiting_signature: {
					page_title: "En attente de signature",
					date: "Date d'envoi pour signature au client",
				},
				waiting_transfer_sg: {
					page_title: "À transmettre SDG",
					date: "Date d'envoi à la société de gestion",
					button: "Transmettre",
				},
				waiting_validation: {
					page_title: "En attente de validation",
					date: "Date d'envoi SDG",
				},
				validated: {
					page_title: "Validées",
					date: "Date de validation",
				},
				modal: {
					title: "Selectionnez une date",
					description: "Veuillez indiquer la date de validation de la souscription",
				},
			},
		},
		mo_subscription_summary: {
			page_title: "Souscriptions informations",
			title: "Souscriptions",
			cgp: "CGP :",
			email_cgp: "Email :",
			office: "Cabinet",
			document_management: "Gestion des documents",
			scpi: "SCPI :",
			nbParts: "Nb parts :",
			cabinet: "Cabinet :",
			subscribed_amount: "Montant souscrit :",
			reception_date: "Date de réception :",
			validation_date: "Date de validation :",
			due_date: "Date de jouissance :",
			payout_date: "Date de versement des premiers loyers :",
			sub_pages: {
				subscribers: {
					page_title: "Souscripteur(s)",
				},
				payment: {
					page_title: "Mode de règlement",
				},
				bulletin: {
					page_title: "Bulletin de souscription",
				},
				files: {
					page_title: "Pièces justificatives",
				},
				signable_documents: {
					page_title: "Documents de souscription",
				},
				signed_documents: {
					page_title: "Documents signés",
				},
			},
		},
		partners: {
			tabs: {
				users: "Conseillers",
				offices: "Cabinets",
			},
			page_title: "Partenaires",
			title: "Partenaires",
			dopdown_button: "Créer un partenaire",
			table: {
				columns: {
					name: "Nom",
					first_name: "Prénom",
					cabinet: "Cabinet",
					id: "Identifiant",
					raison_social: "Cabinets",
					creation_date: "Date de création",
					number_of_subscriptions: "Nombre de souscriptions",
					last_login_at: "Date de dernière connexion",
				},
			},
			pages: {
				offices: {
					tabs: {
						offices: "Données cabinet",
						users: "Conseillers",
					},
				},
				offices_details: {
					buttons: {
						edit: "Modifier les informations",
						save: "Sauvegarder",
					},
					identite_juridique: {
						title: "Identité juridique",
						company_name: "Raison sociale*",
						forme_juridique: "Forme juridique*",
						siret: "Numéro d'identification de l'entreprise (SIRET, RIDET, T.A.H.I.T.I.)*",
						rcs: "RCS*",
						capital_social: "Capital social*",
					},
					habilitations: {
						title: "Habilitations & autorisations",
						type_conseiller: "Type du conseiller*",
						habilitations: "Habilitations*",
						association_professionnelle: "Association professionnelle",
						numero_orias: "N°Orias",
						ligne_metier: "Ligne de metier",
					},
					gerant: {
						title: "Données du gérant",
						nom: "Nom*",
						prenom: "Prénom*",
						date_naissance: "Date de naissance*",
						email_gerant: "Email du gérant*",
					},
					coordonnes_cabinet: {
						title: "Coordonnées du cabinet",
						email_administratif: "Email administratif",
						numero_telephone: "N° de téléphone fixe",
						adresse: "Adresse (n° + rue + ville + code postal)*",
					},
					donnees_bancaires: {
						title: "Données bancaires (commission)",
						titulaire_compte: "Titulaire du compte*",
						iban: "IBAN*",
						bic: "BIC*",
					},
					produits_cabinet: {
						title: "Les produits auxquels le cabinet a accès",
						check_all_spci: "Cocher toutes les SCPI",
					},
				},
				user_details: {
					send_reminder_email_to: "Envoyer un email d'invitation à:",
					form: {
						last_name: {
							label: "Nom",
							placeholder: "Nom",
						},
						first_name: {
							label: "Prénom",
							placeholder: "Prénom",
						},
						birth_date: {
							label: "Date de naissance",
						},
						email: {
							label: "Email principal (adresse email de connexion)",
							placeholder: "Indiquer l'email",
						},
						phone_number: {
							label: "N° de téléphone mobile",
							placeholder: "N° de téléphone mobile",
						},
						etat_compte: {
							label: "Etat du compte",
							placeholder: "Etat du compte",
						},
						signature_date: {
							label: "Date de signature",
						},
						creation_date: {
							label: "Date de création",
						},
					},
				},
			},
		},
		create_partner: {
			page_title: "Create partner",
			sub_pages: {
				create_office: {
					title: "Création / Sélection d'un cabinet",
					find_office: "Chercher un cabinet",
					existing_office: "Cabinet existant",
					new_office: "Nouveau Cabinet",
					legal_identity: "Identité juridique",
					authorisation: "Habilitations & autorisations",
					manager_data: "Données du représentant légal",
					office_coordinates: "Coordonnées du cabinet",
					bank_data: "Données bancaires (commissions)",
					office_products: "Les produits auxquels le cabinet a accès",
					check_all_spci: "Cocher toutes les SCPI",
					form: {
						company_name: {
							label: "Raison sociale *",
							placeholder: "Raison sociale",
						},
						siret: {
							label: "Numéro d'identification de l'entreprise (SIRET, RIDET, T.A.H.I.T.I) *",
							placeholder: "Numéro d'identification de l'entreprise",
						},
						legal_form: {
							label: "Forme juridique *",
							placeholder: "Choisir",
						},
						rcs: {
							label: "RCS *",
							placeholder: "Lieu du RCS",
						},
						share_capital: {
							label: "Capital social *",
							placeholder: "Montant du capital social",
						},
						type_advisor: {
							label: "Type du conseiller *",
							placeholder: "Choisir le type",
						},
						habilitations: {
							label: "Habilitations *",
						},
						professional_association: {
							label: "Association professionnelle *",
							placeholder: "Entrer l'association",
						},
						numero_orias: {
							label: "N°Orias *",
							placeholder: "N°Orias",
						},
						business_lines: {
							label: "Ligne de metier *",
						},
						name: {
							label: "Nom *",
							placeholder: "Nom",
						},
						first_name: {
							label: "Prénom *",
							placeholder: "Prénom",
						},
						birth_date: {
							label: "Date de naissance *",
						},
						email_manager: {
							label: "Email du gérant *",
							placeholder: "Indiquer l'email",
						},
						email_administratif: {
							label: "Email administratif",
							placeholder: "Indiquer l'email",
						},
						phone_number: {
							label: "N° de téléphone fixe",
							placeholder: "N° de téléphone fixe",
						},
						address: {
							label: "Adresse (n° + rue + ville + code postal) *",
							placeholder: "Adresse (n° + rue + ville + code postal)",
						},
						account_owner: {
							label: "Titulaire du compte *",
							placeholder: "Titulaire du compte",
						},
						iban: {
							label: "IBAN *",
							placeholder: "IBAN",
						},
						bic: {
							label: "BIC *",
							placeholder: "BIC",
						},
					},
				},
				create_cgp: {
					title: "Création d'un CGP",
					data_advisor: "Données du conseiller",
					partner_account: "Compte partenaire",
					message_no_office_selected: "Aucun cabinet n'a été créé ou selectionné, veuillez revenir à l'étape précédente",
					fill_with_manager_data: "Remplir avec les données du représentant légal",
					form: {
						last_name: {
							label: "Nom *",
							placeholder: "Nom",
						},
						first_name: {
							label: "Prénom *",
							placeholder: "Prénom",
						},
						birth_date: {
							label: "Date de naissance *",
						},
						email: {
							label: "Email principal (adresse email de connexion) *",
							placeholder: "Indiquer l'email",
						},
						phone_number: {
							label: "N° de téléphone mobile *",
							placeholder: "N° de téléphone mobile",
						},
						etat_compte: {
							label: "Etat du compte *",
							placeholder: "Etat du compte",
						},
						signature_date: {
							label: "Date de signature *",
						},
						creation_date: {
							label: "Date de création *",
						},
					},
				},
			},
			back: "Retour au Middle Office",
		},
	},
	component: {
		status_tracker_modal: {
			title: "Suivi du dossier",
			rto_signature: "E-signature convention RTO",
			subscription_signature: "E-signature dossier de souscription",
			send_signed_documents: "Transmettre le dossier signé à la société de gestion",
			validation: "Validation du dossier par la société de gestion",
			waiting_signature: "{{name}} : En attente de signature",
			signed: "{{name}} : Signé",
			declined: "{{name}} : Refusé",
			expired: "Demande de signature expiré",
			waiting_transfer_sg: "En cours de transmission",
			file_transmited: "Dossier transmis",
			waiting: "En attente",
			file_validated: "Dossier validé",
			validate: "Valider",
			transmit: "Transmettre",
			close: "Fermer",
			resend: "Renvoyer une demande",
			resend_document_modal: {
				title: "Renvoyer une demande",
				description: "Les clients ayant déjà signé devront signer à nouveau si vous renvoyez cette demande.",
				cancel: "Annuler",
				confirm: "Renvoyer une demande",
				transfered: "Transmis à la société de gestion avec succès",
				success: "Demande envoyée avec succès !",
			},
		},
		status_tracker: {
			status: {
				declined: "Un signataire a refusé de signer",
				expired: "Le délai de signature a expiré",
				processing: "En cours de traitement",
				waiting_signature: "En cours de signature",
				waiting_transfer_sg: "À transmettre à la société de gestion",
				waiting_validation: "En attente de validation",
				validated: "Validé",
			},
			follow_up: "Suivi du dossier",
		},
		menu: {
			question: "Une question ?",
			contact: "Contacter Wenimmo",
		},
		signature_alert_banner: "Enregistrez votre signature manuscrite pour simplifier et accélérer vos souscriptions",
		form: {
			optional: "facultatif",
			apportCompteCourant: "Précisez l'origine de l'apport en compte courant",
			paymentByTier: "Le paiement est effectué par un tiers",
			paymentByTierMotive: "Précisez le motif",
			paymentFromFrenchAccount: "Le paiement provient d'un compte français",
			paymentFromCountry: "Précisez le pays",
			civility: "Civilité",
			last_name: "Nom",
			first_name: "Prénom(s)",
			maiden_name: "Nom de jeune fille",
			email: "E-mail",
			office_name: "Mon cabinet",
			mobile_phone: "Téléphone portable",
			spouse: {
				first_name: "Prénom du conjoint",
				last_name: "Nom du conjoint",
				email: "E-mail du conjoint",
				mobile_phone: "Téléphone portable du conjoint",
				birth_date: "Date de naissance du conjoint",
				birth_place: "Lieu de naissance du conjoint",
			},
			address: "Adresse",
			address_automatique: "Adresse (recherche automatique)",
			address_automatique_tooltip: "Pour les TOM, nous vous invitons à indiquer l'adresse manuellement et ne pas utiliser la recherche automatique ",
			postal_code: "Code postal",
			city: "Ville",
			country: "Pays",
			nationality: "Nationalité",
			marital_status: "Situation familiale",
			matrimonial_regime: "Régime matrimonial",
			birth_date: "Date de naissance",
			birth_place: "Lieu de naissance",
			birth_department: "Département de naissance (numéro du département)",
			birth_country: "Pays de naissance",
			profession: "Profession (si retraité ancienne profession)",
			line_of_businness: "Secteur d'activité",
			category_social_profession: "Catégorie socio-professionnelle",
			fiscal_regime: "Régime fiscal",
			nif: "Numéro d'Identification Fiscale (NIF)",
			tax_residence_country: "Pays de résidence fiscale",
			legal_capacity: "Capacité juridique",
			politically_exposed_person: "Personne politiquement exposée",
			us_person: "US Person",
			agreement_electronic_document:
				"Accord pour envoi des documents dématérialisés par voie électronique (bordereau fiscal / distribution de revenus / convocations aux AG / publication règlementaire…)*",
			non_professional_classification_acknowledgment:
				"Le ou les souscripteur(s) est(sont) informé(s) que la société de gestion classe les souscripteurs dans la catégorie « client non professionnel » leur permettant ainsi de bénéficier du régime de protection le plus favorable.",
			message: {
				info: {
					recommandation_rib: "Co-souscription recommandée avec RIB compte joint SAUF si réemploi de fonds propres (attestation à fournir)",
				},
				warning: {
					next_step: {
						text: "Afin de passer à l'étape suivante",
						mailTo: "Contactez Wenimmo",
					},
					age_restriction_old: "Votre client a plus de 80 ans : il est possible qu'un document complémentaire vous soit demandé",
					age_restriction_minor: "Votre client est mineur : il est possible qu'un document complémentaire vous soit demandé",
					tax_residence_country_france: "Si différent de France : Merci de prendre contact avec Wenimmo",
					non_professional_classification_acknowledgment: "Nous vous invitons à contacter Wenimmo afin de classer votre client en « client professionnel »",
				},
			},
			payment_methods: {
				description: "Méthode de paiement choisie : ",
				titulary_name: "Nom du titulaire",
				bank_domiciliation: "Domiciliation bancaire",
				iban: "IBAN",
				bic: "BIC",
				organism_name: "Nom de l'organisme",
				download_rib: "Télécharger le RIB",
				nantie_parts: "Les parts seront-elles nanties ?",
				loan_amount: "Montant du prêt",
			},
			funds_origin: "Origine des fonds",
			funds_origin_other: "Veuillez préciser",
			funds_origin_savings: "Précisez l'origine de l'épargne",
			financial_objective: "Objectif d'investissement",
			signature_place: "Lieu de signature (en France uniquement)",
			community_investment: {
				question: "En cas de régime de communauté, merci de précisez si les souscriptions sont effectuées avec : ",
				co_subscription_recommendation: "co-souscription recommandée",
			},
			community_funds_origin: "Origine des fonds propres",
			community_funds_origin_other: "Si autre, précisez",
			corporation: {
				raison_social: "Raison sociale",
				siret: "Siret",
				forme_juridique: "Forme juridique",
				addresse: "Addresse du siège social",
				residence_fiscale: "Résidence fiscale",
				residence_fiscale_other: "Précisez",
				regime_fiscal: "Régime fiscal",
				assujetti_ifi: "Assujetti à l'IFI",
				qualite_representant_legal: "Qualité du représentant légal",
			},
		},
		deposit_document: {
			deposit_documents: "Déposer des documents",
			or_drag_drop: "Ou glissez-déposez vos fichiers ici",
			file_not_autorized: "Fichier non autorisé",
			tooltip_texts: {
				rib: "Si co-souscription, RIB commun recommandé.\n\nSi souscription seule, RIB au nom seul du souscripteur recommandé si fonds propres.",
				funds_origin: `Si 'Revenus professionnels' => justificatif des revenus (bulletin de salaire/avis d'imposition…) + dernier relevé de compte\n\nSi 'Epargne' => relevé d'épargne + dernier relevé de compte\n\nSi 'Emprunt' => copie de l'offre de prêt acceptée et signée\n\nSi 'Donation' => copie de l'acte notarié ou de la déclaration enregistrée auprès des services fiscaux + relevés de comptes justifiant la traçabilité des fonds\n\nSi 'Succession' => copie de l'acte de succession + relevés de comptes justifiant la traçabilité des fonds\n\nSi 'Cession d'actifs de valeurs mobilières' => acte de cession + relevés de compte justifiant la traçabilité des fonds\n\nSi 'Cession d'actifs immobiliers' => acte de cession avec prix + relevés de compte justifiant la traçabilité des fonds\n\nSi 'Rachat d'un contrat d'Assurance-Vie' => copie de la confirmation de rachat + relevés de compte justifiant la traçabilité des fonds\n\nSi 'Autes : Précisez" => justificatif + relevés de compte justifiant la traçabilité des fonds`,
				domicil_proof:
					"Justificatifs de domiciles acceptés :\n\nAssurance habitation ou facture de moins de 3 mois : électricité / gaz / eau / box internet / téléphone (pas de mobile pour SOGENIAL, THEOREIM et ARKEA)\n\nAvis d’imposition :\n- Non accepté pour THEOREIM\n- Accepté si < 3 mois pour ADVENIS/ AESTIAM / KYANEOS\n- Accepté si < 6 mois pour ALTAREA /EURYALE \n- Accepté si < 1 an pour les autres sociétés de gestion",
			},
		},
		tooltip: {
			appreciation_client:
				"Comment êtes-vous entré en relation, quelle est son activité, pourquoi réside-t-il à l'étranger le cas échéant ? Personne morale, tous les associés sont-ils identifiés ?",
			appreciation_operation: "Perspective de l'opération, vous paraît-elle normale et cohérente et pourquoi ? Si les fonds proviennent d'un tiers, quel en est le motif ?",
			non_professional_classification_acknowledgment:
				"Vous pouvez formuler une demande écrite WENIMMO afin d'être classé en « client professionnel » et renoncer à une partie de la protection. Dans ce cas, nous vous remercions de contacter WENIMMO à backoffice@wenimmo.com",
		},
		revenu_charge: {
			nature: "Nature",
			montant: "Montant",
			add: "Ajouter",
		},
	},
};

export default trads;
