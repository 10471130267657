import { EDistributionFrequency } from "common/enums/ProductSheet/EDistributionFrequency";
import { EProductCategories } from "common/enums/Scpi/EProductCategories";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import { useCallback } from "react";
import * as P from "ts-pattern";
import DateUtils from "utils/DateUtils";
import NumberUtils from "utils/NumberUtils";

import BoxValue from "./BoxValue";
import classes from "./classes.module.scss";
import ProductLineElement from "./ProductLineElement";
import NumberInputElement from "components/materials/Form/NumberInputElement";

export enum EProductInformation {
	price = "price",
	category = "category",
	minNumberOfShares = "minNumberOfShares",
	subFees = "subFees",
	dueDate = "dueDate",
	withdrawalPrice = "withdrawalPrice",
	distributionRate = "distributionRate",
	distributionRateN2 = "distributionRateN2",
	distributionFrequency = "distributionFrequency",
	capitalization = "capitalization",
	financialOccupancyRate = "financialOccupancyRate",
	retainedEarnings = "retainedEarnings",
}

enum EUnit {
	PERCENTAGE = "percentage",
	EURO = "euro",
	DAY = "day",
	PART = "part",
	TEXT = "text",
}

export type IDueDate = {
	dueDays: number | null;
	dueMonths: number | null;
};

type IProps = {
	productInformation: EProductInformation;
	defaultValue?: string | number | null | undefined;
	readonly?: boolean;
	dueDate?: {
		value: IDueDate;
		onChange: (value: IDueDate) => void;
	};
	openHistory: (productInformation: EProductInformation) => void;
};

export default function ProductInformation(props: IProps) {
	const { productInformation, defaultValue, readonly = true, dueDate, openHistory } = props;

	const openHistoryCb = useCallback(() => openHistory(productInformation), [openHistory, productInformation]);
	return (
		<div className={classes["root"]}>
			<ProductLineElement
				label={I18n.asset.enums.EProductInformation[productInformation]}
				element={getInput(productInformation, defaultValue, dueDate, readonly)}
				openHistory={openHistoryCb}
			/>
		</div>
	);
}

function getInput(
	productInformation: EProductInformation,
	defaultValue: string | number | null | undefined,
	dueDate?: {
		value: IDueDate;
		onChange: (value: IDueDate) => void;
	},
	readonly?: boolean,
) {
	return P.match(productInformation)
		.with(EProductInformation.price, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.EURO)} />
			) : (
				<InputElement
					type={EInputType.NUMBER}
					name="price"
					min={0}
					step={0.01}
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					placeholder={EUnit.EURO}
					size="small"
				/>
			),
		)
		.with(EProductInformation.category, () =>
			readonly ? (
				<BoxValue value={toFormat(I18n.asset.enums.EProductCategories[defaultValue as EProductCategories])} />
			) : (
				<MuiSelectInputElement
					name="category"
					defaultValue={{ id: defaultValue as EProductCategories, label: I18n.asset.enums.EProductCategories[defaultValue as EProductCategories] }}
					options={Object.values(EProductCategories).map((category) => ({
						id: category,
						label: I18n.asset.enums.EProductCategories[category],
					}))}
					size="small"
				/>
			),
		)
		.with(EProductInformation.minNumberOfShares, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.PART)} />
			) : (
				<InputElement
					name="minNumberOfShares"
					min={0}
					type={EInputType.NUMBER}
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					placeholder={EUnit.PART}
					size="small"
				/>
			),
		)
		.with(EProductInformation.subFees, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.PERCENTAGE)} />
			) : (
				<InputElement
					name="subFees"
					min={0}
					max={100}
					type={EInputType.NUMBER}
					step={0.01}
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					placeholder={EUnit.PERCENTAGE}
					size="small"
				/>
			),
		)
		.with(EProductInformation.dueDate, () =>
			readonly ? (
				<BoxValue value={toFormat(DateUtils.getDueDateMessage(dueDate?.value.dueDays, dueDate?.value.dueMonths), EUnit.DAY)} />
			) : (
				<div className={classes["due-date-container"]}>
					<InputElement
						name="dueDays"
						min={0}
						max={31}
						type={EInputType.NUMBER}
						defaultValue={dueDate?.value.dueDays}
						placeholder={I18n.asset.pages.product.informations.days}
						onChange={(e) => {
							const dueDays = Number(e.target.value);
							dueDate?.onChange({ dueDays, dueMonths: dueDate?.value.dueMonths ?? 0 });
						}}
						size="small"
					/>
					<InputElement
						name="dueMonths"
						min={0}
						max={12}
						type={EInputType.NUMBER}
						defaultValue={dueDate?.value.dueMonths}
						placeholder={I18n.asset.pages.product.informations.months}
						onChange={(e) => {
							const dueMonths = Number(e.target.value);
							dueDate?.onChange({ dueDays: dueDate?.value.dueDays ?? 0, dueMonths });
						}}
						size="small"
					/>
					<BoxValue value={toFormat(DateUtils.getDueDateMessage(dueDate?.value.dueDays, dueDate?.value.dueMonths), EUnit.DAY)} />
				</div>
			),
		)
		.with(EProductInformation.withdrawalPrice, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.EURO)} />
			) : (
				<InputElement
					type={EInputType.NUMBER}
					name="withdrawalPrice"
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					min={0}
					step={0.01}
					placeholder={EUnit.EURO}
					size="small"
				/>
			),
		)
		.with(EProductInformation.distributionRate, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.PERCENTAGE)} />
			) : (
				<InputElement
					name="distributionRate"
					min={0}
					max={100}
					type={EInputType.NUMBER}
					step={0.01}
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					placeholder={EUnit.PERCENTAGE}
					size="small"
				/>
			),
		)
		.with(EProductInformation.distributionRateN2, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.PERCENTAGE)} />
			) : (
				<InputElement
					name="distributionRateN2"
					min={0}
					max={100}
					type={EInputType.NUMBER}
					step={0.01}
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					placeholder={EUnit.PERCENTAGE}
					size="small"
				/>
			),
		)
		.with(EProductInformation.distributionFrequency, () =>
			readonly ? (
				<BoxValue value={toFormat(I18n.asset.enums.EDistributionFrequency[defaultValue as EDistributionFrequency])} />
			) : (
				<MuiSelectInputElement
					name="distributionFrequency"
					defaultValue={{ id: defaultValue as EDistributionFrequency, label: I18n.asset.enums.EDistributionFrequency[defaultValue as EDistributionFrequency] }}
					options={Object.values(EDistributionFrequency).map((distributionFrequency) => ({
						id: distributionFrequency,
						label: I18n.asset.enums.EDistributionFrequency[distributionFrequency],
					}))}
					size="small"
				/>
			),
		)
		.with(EProductInformation.capitalization, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.EURO)} />
			) : (
				<NumberInputElement name="capitalization" min={0} step={0.01} defaultValue={setToUndefinedIfNegative(defaultValue)} placeholder={EUnit.EURO} size="small" />
			),
		)
		.with(EProductInformation.financialOccupancyRate, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.PERCENTAGE)} />
			) : (
				<InputElement
					name="financialOccupancyRate"
					min={0}
					max={100}
					type={EInputType.NUMBER}
					step={0.01}
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					placeholder={EUnit.PERCENTAGE}
					size="small"
				/>
			),
		)
		.with(EProductInformation.retainedEarnings, () =>
			readonly ? (
				<BoxValue value={toFormat(defaultValue, EUnit.DAY)} />
			) : (
				<InputElement
					name="retainedEarnings"
					type={EInputType.NUMBER}
					min={0}
					step={0.01}
					defaultValue={setToUndefinedIfNegative(defaultValue)}
					placeholder={EUnit.DAY}
					size="small"
				/>
			),
		)
		.otherwise(() => null);
}

function toFormat(value: string | number | null | undefined, unit = EUnit.TEXT) {
	if (value === null || value === undefined) return "-";
	if (typeof value === "number" && value < 0) return "-";
	switch (unit) {
		case EUnit.TEXT:
			return value.toString();
		case EUnit.PERCENTAGE:
			return `${value} %`;
		case EUnit.EURO:
			return NumberUtils.formatNumberAddingSpaces(Number(value) ?? 0, 2) + " €";
		case EUnit.DAY:
			return Number(value) >= 2 ? `${value} ${I18n.trslt(I18n.asset.pages.product.informations.days)}` : `${value} ${I18n.trslt(I18n.asset.pages.product.informations.day)}`;
		case EUnit.PART:
			return Number(value) >= 2
				? `${value} ${I18n.trslt(I18n.asset.pages.product.informations.shares)}`
				: `${value} ${I18n.trslt(I18n.asset.pages.product.informations.share)}`;
		default:
			return value.toString();
	}
}

function setToUndefinedIfNegative(value: string | number | null | undefined) {
	if (value === null || value === undefined) return value;
	if (typeof value === "number" && value < 0) return undefined;
	return value;
}
